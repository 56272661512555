<template>
  <div>

    <div class="top">
      <v-btn v-if="isPlaying"  fab  @click="togglePlay()" color="secondary" dark width="80" height="80">
        <v-icon x-large>
          mdi-pause
        </v-icon>
      </v-btn>
    </div>

    <div class="top pp-drawer" v-if="!isPlaying" >
      <v-btn  fab  @click="togglePlay()" color="secondary" dark width="80" height="80">
        <v-icon x-large >
          mdi-play
        </v-icon>
      </v-btn>
    </div>

    <div class="info-btn ma-5">
      <v-icon x-large v-if="isPlaying"  fab  @click="togglePlay()" class="mt-2">
        mdi-information-outline
      </v-icon>
    </div>

    <div class="info-btn ma-5">
      <v-icon x-large v-if="!isPlaying"  fab  @click="togglePlay()" color="primary" class="mt-2" @mouseleave="hover = false">
        mdi-information-outline
      </v-icon>
    </div>

    <!-- Pause Screen -->
    <div class="pp-blue v-card--reveal white--text pp-desc" v-if="!isPlaying">
      <v-row>
        <div class="pa-10 mt-16 ml-lg-16 ml-5">

          <v-img
              src="/images/lp-logo-2.png"
              height="40"
              contain
              position="left"
              class="mb-8"
          >
          </v-img>

          <div class="uppercase outline text-h4 text-md-h2 font-weight-bold mb-6 pp-card">
            {{video.title}}
          </div>

          <div class="text-subtitle-1 text-md-h5 mb-8 pp-card">
            {{video.description}}
          </div>

          <v-btn x-large rounded outlined class=" px-6 mb-10" dark @click="togglePlay()">
            Continue Learning
            <v-icon class="ml-4">
              mdi-arrow-right-circle-outline
            </v-icon>
          </v-btn>
        </div>
      </v-row>
    </div>
    <!-- End of Pause Screen -->

    <v-row>
      <v-card class="ml-3 mx-auto" color="grey darken-2">
        <div class="mt-8">
          <v-app-bar-nav-icon @click="drawer = true" class="ma-3 lp-hover" x-large></v-app-bar-nav-icon>
        </div>

        <div class="ma-4 mt-16 pl-1 pt-16 ">
          <v-icon @click="doStopVideo()" class="lp-hover mt-5" large>
            mdi-home
          </v-icon>
        </div>
      </v-card>

      <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
          width="380"
          color="grey darken-4">
        <v-row>
          <v-col cols="1">
            <v-btn @click="drawer = false" small class="mx-5 mt-5" color="secondary" outlined height="32">
              <v-icon dark @click="drawer = false">
                mdi-chevron-left
              </v-icon>
            </v-btn>
          </v-col>

          <v-col cols="8">
            <v-img src="/images/lp-logo-2.png"
                   height="70"
                   contain
                   class="close">
            </v-img>
          </v-col>
        </v-row>

        <v-list class="px-2" nav dense dark >
          <v-list-group v-for="course in courses" v-bind:key="course.uuid" active-class="primary--text text--accent-4" >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title class="white--text">{{course.name}}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(newVideo, i) in course.resources" :key="i" link dark>
              <v-list-item-title v-text="newVideo.title" v-on:click="playVideo(newVideo)" class="primary--text"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-if="shouldPlay(newVideo)" class="pl-2" color="primary" v-on:click="playVideo(newVideo)">
                  mdi-play
                </v-icon>
                <v-icon v-if="shouldPause(newVideo)" class="pl-2" color="primary" v-on:click="togglePlay()">
                  mdi-pause
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>



      <v-col>
        <div style="background-color: transparent; position: absolute; top: 0; left: 80px; z-index: 5; height: 96.5vh; width: 100%; cursor: pointer" @click="togglePlay()">
        </div>

        <video-player
            v-if="playing && video"
            :isPlaying="isPlaying"
            :aspect-ratio="video.aspectRatio"
            :autoplay=true
            style="width: 100%; height: 100vh;"
            data-setup='{ "youtube": { "customVars": { "ytp-chrome-top": "transparent" } } }'
            showinfo=0
            autohide=1
            modestbranding="1"
            rel="0"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import VideoPlayer from "./VideoPlayer";

export default {
  name: "YouTubeWithCourses",
  props: ["data"],
  components: {
    VideoPlayer,
  },
  data(){
    return {
      isPlaying: true,
      drawer: false,
      group: null,
      dialog: false,
      youtube:{
        techOrder: ["youtube"],
        youtube: {
          modestbranding: 1,
          wmode: "transparent",
          controls: 2,
          iv_load_policy: 3,
          rel: 0,
          ytControls: 0
        }
      },
    }
  },
  computed: {
    ...mapState(["courses", "playing", "video"]),
    ...mapGetters(["courses", "playing", "video"]),
  },
  methods: {
    ...mapActions(["doStopVideo", "doPlayVideo"]),
    playVideo(video){
      this.doPlayVideo(video);
      this.isPlaying = true;
    },
    shouldPlay(checkVideo){

      if(this.video === checkVideo && !this.isPlaying){
        return true
      }

      return this.video !== checkVideo
    },
    shouldPause(checkVideo){
      return this.video === checkVideo && this.isPlaying
    },
    togglePlay(){
      this.isPlaying = !this.isPlaying
    },
  },
}
</script>

<style scoped>

.ytp-chrome-top {
  display: none !important;
}

.lp-hover:hover {
  color: #348ccf !important;
}

.close {
  left: 60px;
}

.info-btn {
  position: absolute;
  top: 160px;
  z-index: 1;
}

.v-card--reveal {
  position: absolute;
  transition: 0.1s !important;
}

.pp-blue {
  background-image: url("/images/slider-2.jpg"), linear-gradient(330deg, rgba(255, 255, 255, 0) 0%, rgba(73, 41, 41, 0) 30%, #0057a8 70%, #004889 100%);
  opacity: 0.95;
  border-radius: 0 0 0 10px;
  background-size: cover;
  height: 96.5vh !important;
}

.pp-desc {
  height: 80%;
  z-index: 5;
  left: 87px;
  top: 0;
  width: 100%;
}

.pp-card {
  width: 70%;
}

@media only screen and (max-width: 1024px){
  .pp-blue {
    height: fit-content;
  }

  .pp-card {
    width: 40%;
  }
}


@media only screen and (min-width: 1700px) {
  .pp-blue {
    height: 98vh !important;
  }
}



</style>