<template>
  <div>
    <Header/>
    <CourseList/>
    <Footer/>
  </div>
</template>

<script>
import Header from "../components/Header";
import CourseList from "../components/CourseList";
import Footer from "../components/Footer";
export default {
  name: "Home",
  components: {Footer, CourseList, Header}
}
</script>