<template>
  <v-container v-if="! this.playing">
    <header>
      <v-row>
        <v-col cols="3">
          <v-img
              src="/images/lp-logo-2.png"
              height="50"
              contain
              class="mt-4"
          ></v-img>
        </v-col>

        <v-col>
          <v-autocomplete
              filled
              rounded
              color="primary"
              dark
              v-model="model"
              :loading="isLoading"
              :items="items"
              :search-input.sync="search"
              cache-items
              class="my-auto mt-3 search-bar"
              label="Search Videos"
              placeholder="Start typing to Search"
              item-text="search"
              item-value="search"
              return-object
              clearable
          >
            <template v-slot:selection="data" class="pp-result">
              <template v-if="data.item.type === 'course'" class="white--text">
                {{data.item.course.name}}
              </template>
              <template v-else class="white--text">
                {{data.item.video.title}}
              </template>
            </template>
            <template v-slot:item="data">
              <template v-if="data.item.type === 'course'">
                <v-list-item-content>
                  <v-list-item-title class="blue--text font-weight-bold">Course: {{data.item.course.name}}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content >
                  <v-list-item-title v-html="data.item.video.title" class="blue--text font-weight-bold"></v-list-item-title>
                  <div>
                    <div v-html="data.item.video.description" class="text-subtitle-2 pp-capt white--text" >
                    </div>
                  </div>

                </v-list-item-content>
              </template>
            </template>

          </v-autocomplete>
        </v-col>

      </v-row>
    </header>

  </v-container>

</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import router from "../router";
import shared from "../shared"

export default {
  name: "Header",
  computed: {
    ...mapState(["loading", "courses", "playing"]),
    ...mapGetters(["loading", "courses", "playing"]),
    items () {

      let videos = []

      this.courses.forEach(function(course){
        videos.push({
          type: 'course',
          course: course,
          search: `${course.name} ${course.description}`
        })

        course.resources.forEach(function(video){

          videos.push({
            type: 'video',
            course: course,
            video: video,
            search: `${course.name} ${course.description} ${video.title} ${video.description}`
          })

        })
      })

      return videos

    },
  },
  methods: {
    ...mapActions(["doPlayVideo"]),
  },
  data: () => ({
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),
  watch: {
    model(current) {

      if(! current){
        return;
      }

      if(! current.video){
        // It's a course, jump to it
        router.push({ path: `/#${shared.courseToAnchor(current.course)}`});
      }else{
        // It's a video, play it
        this.doPlayVideo(current.video);
      }

    }
  },
}
</script>

<style scoped>
.search-bar {
  height: 40px;
  display: flex;
  width: 100%;
  padding-left: 16px;
  border-radius: 4px;
}

.search-bar:active {
  width: 100%;
  height: 100%;
  border: none;
  background-color: primary;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  padding: 0 20px 0 40px;
  box-shadow: 0 0 0 2px rgb(134 140 160);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 16px 48%;
  color: var(--primary);
}

.pp-capt {
  width: 500px !important;
}

.pp-result {
  background-color: #ff0000 !important;
}

.v-sheet.v-list:not(.v-sheet--outlined) {
  background-color: rgb(33, 31, 31);
}


.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: white !important;
}

</style>