<template>
  <v-container fluid class="ma-0 pa-0" style="position: relative; bottom: 0;" v-if="!playing">
    <v-card color="grey darken-4 pa-10 mt-16">
      <v-divider class="mt-4" dark></v-divider>
      <v-row>
        <v-col>
          <v-card-text class="grey--text">
            PeoplePlus Group Ltd part of Staffline Group plc. 19-20 The Triangle, NG2 Business Park, Nottingham, NG2 1AE. Registered in England & Wales.
            Company number: 5722765 Copyright © 2020 PeoplePlus Group Limited
          </v-card-text>
        </v-col>

        <v-col cols="2">
          <v-img
              src="/images/pp-logo.png"
              height="40"
              contain
              class="mt-4"
          ></v-img>
        </v-col>

        <v-col cols="2">
          <v-img
              src="/images/lp-logo-2.png"
              height="40"
              contain
              class="mt-4"
          ></v-img>
        </v-col>


      </v-row>

    </v-card>
  </v-container>

</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapState(["playing"]),
    ...mapGetters(["playing"]),
  },
}
</script>