<template>
    <video ref="videoPlayer" class="video-js vjs-default-skin" controls autoplay></video>
</template>

<script>

import videojs from 'video.js';
import '../../node_modules/video.js/dist/video-js.css'
import 'videojs-youtube';
import {mapGetters, mapState} from "vuex";

export default {
  name: "VideoPlayer",
  computed: {
    ...mapState(["video"]),
    ...mapGetters(["video"]),
  },
  props: {
    options: {
      type: Object,
      default(){
        return {};
      }
    },
    isPlaying:{
      type: Boolean,
      default(){
        return false;
      }
    },
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {

    this.options.sources = [{
      type: "video/youtube",
      src: this.video.uri
    }];

    this.options.poster = this.video.image;

    this.player = videojs(this.$refs.videoPlayer, this.options)
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  watch: {
    isPlaying: function(newValue){
      if(newValue === false) {
        this.player.pause()
      }else{
        this.player.play()
      }
    },
    video: function(newValue){

      this.player.src({
        type: "video/youtube",
        src: newValue.uri
      });
      this.player.poster(newValue.image);

    }
  }
}

</script>

<style>
/* These don't work yet but should be hidden in the interface! */
.ytp-chrome-top {
  display: none !important;
}
.ytp-watermark {
  display: none !important;
}
</style>