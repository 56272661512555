<template>
  <v-container>
    <v-card flat color="transparent">
      <div class="text-md-h4 text-sm-h5 primary--text font-weight-bold uppercase">
        {{video.title}}
      </div>
      <div class="text-md-h7 white--text mt-3 pr-5">
        {{video.description}}
      </div>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "VideoDetail",
  props: ["course", "video"],
  methods: {
    ...mapActions(["doPlayVideo"]),
  },
}
</script>

<style scoped >

</style>