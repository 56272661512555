<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-card
          class="ma-2 app-card"
      >
        <div class="yt">
          <v-img class="rounded-lg"
              :lazy-src="data.image"
              max-width="720"
              :src="data.image"
          ></v-img>
        </div>

        <v-expand-transition>
          <div
              v-if="hover"
              class="transition-fast-in-fast-out darken-2 v-card--reveal text-h2 white--text"
              style="height: 100%;"
          >

            <div @click="doPlayVideo(data)">
              <v-main class="pa-6">
                <v-img
                    src="/images/pp-intersection.png"
                    contain
                    class="hidden-sm-and-down pp-intersection"
                >
                </v-img>
              </v-main>
            </div>
          </div>
        </v-expand-transition>
      </v-card>
    </v-hover>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "VideoSplash",
  props: ["data"],
  computed: {
    ...mapState(["playing", "video", "courses"]),
    ...mapGetters(["playing", "video", "courses"]),
  },
  methods: {
    ...mapActions(["doPlayVideo", "doStopVideo"]),
  },
}
</script>

<style scoped>
.yt {
  align-self: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width:100%;
  background-image: linear-gradient(to left, rgba(34,2,1,0), rgba(0, 88, 147, 0.8), rgba(21, 35, 48, 0.55));
  z-index: 1;
  left: -1px;
  border-radius: 15px;
}

.app-card {
  padding: 5px;
  background-color: rgba(57, 57, 57, 0.56) !important;
  border-radius: 15px !important;
  border: 1px solid #535353;
  box-shadow: 2px 2px 7px #275366 !important;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .pp-intersection {
    height: 50px;
  }
}

@media only screen and (min-width: 1024px) {
  .pp-intersection {
    height: 130px;
  }
}

@media only screen and (min-width: 1700px) {
  .pp-intersection {
    height: 190px;
    top: 25px;

  }
}



</style>