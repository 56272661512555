<template>
  <div>
    <YouTubeWithCourses v-if="playing"></YouTubeWithCourses>
    <v-container fluid class="ma-0 ">

      <div class="hooper-bg px-sm-10" v-if="!playing">
        <Hooper id="banner" :settings="hopperBannerSettings">
          <Slide v-for="course in courses" v-bind:key="course.uuid">
            <v-container>
              <v-row justify="center">
                <v-col cols="4" class="hidden-sm-and-down pa-0">
                  <v-img class="box"
                           :lazy-src="course.image"
                           :src="course.image"
                           height="370"
                           contain
                  >
                  </v-img>
                </v-col>
                <v-col>
                  <div class="text-h3 font-weight-bold mt-4 white--text ml-4 pp-width uppercase">
                    {{course.name}}
                  </div>
                  <v-card-subtitle class="mt-2 white--text pp-width text-h7 mb-4" >
                    {{course.description}}
                  </v-card-subtitle>
                  <v-btn large rounded outlined class="ml-4 px-6" dark @click="jump(course)">
                    View Episodes
                    <v-icon class="ml-4">
                      mdi-arrow-right-circle-outline
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </Slide>
          <hooper-navigation slot="hooper-addons">
            <svg slot="hooper-prev" class="pp-addons prev">
              <path d="M15.987.563A15.425,15.425,0,1,0,31.412,15.987,15.422,15.422,0,0,0,15.987.563ZM8.9,14.93,17.331,6.5a1.487,1.487,0,0,1,2.108,0L20.5,7.56a1.487,1.487,0,0,1,0,2.108l-6.319,6.319L20.5,22.306a1.487,1.487,0,0,1,0,2.108l-1.057,1.057a1.487,1.487,0,0,1-2.108,0L8.9,17.045A1.494,1.494,0,0,1,8.9,14.93Z"/>
            </svg>
            <svg slot="hooper-next" class="pp-addons">
              <path d="M15.987.563A15.425,15.425,0,1,1,.563,15.987,15.422,15.422,0,0,1,15.987.563ZM23.072,14.93,14.644,6.5a1.487,1.487,0,0,0-2.108,0L11.478,7.56a1.487,1.487,0,0,0,0,2.108L17.8,15.987l-6.319,6.319a1.487,1.487,0,0,0,0,2.108l1.057,1.057a1.487,1.487,0,0,0,2.108,0l8.428-8.428A1.494,1.494,0,0,0,23.072,14.93Z"/>
            </svg>
          </hooper-navigation>
        </Hooper>
      </div>

      <div v-if="!playing">
        <div v-for="course in courses" v-bind:key="course.uuid">
          <v-row justify="center">
            <v-col class="pl-xl-20" style="z-index: 1">
              <div class="text-h6 text-md-h2 text-sm-h4 pl-4 font-weight-bold mt-16 uppercase outline primary--text pp-title" v-bind:id="anchor(course)">
                {{course.name}}
              </div>
              <div class="text-md-h6 text-sm-subtitle-1 grey--text pa-4 pp-large-2">
                {{course.description}}
              </div>
            </v-col>
            <v-col class="pr-xl-20 hidden-xs-only" cols="6">
              <div class="pp-tablet">
                <v-img class="pp-img mt-sm-12 pp-large pp-medium"
                       :lazy-src="course.playerImage"
                       :src="course.playerImage"
                       contain
                >
                </v-img>
              </div>

            </v-col>
          </v-row>

          <v-row class="box-outlined" >
            <Hooper
                v-bind:ref="refHooperCourses(course)"
                @slide="updateCarousel($event, course)"
                :settings="hooperVideosSettings"
                class="py-5 px-12"
                style="height: inherit"
            >
              <Slide v-for="(video, place) in course.resources" :key="video.uuid" :index="video.uuid" >
                <div v-on:mouseover="updateCarouselFromHover(course, place)">
                  <VideoSplash v-bind:data="video" />
                </div>
              </Slide>
              <HooperNavigation slot="hooper-addons" >
                <svg slot="hooper-prev" class="pp-addons prev ar r-left" >
                  <path d="M15.987.563A15.425,15.425,0,1,0,31.412,15.987,15.422,15.422,0,0,0,15.987.563ZM8.9,14.93,17.331,6.5a1.487,1.487,0,0,1,2.108,0L20.5,7.56a1.487,1.487,0,0,1,0,2.108l-6.319,6.319L20.5,22.306a1.487,1.487,0,0,1,0,2.108l-1.057,1.057a1.487,1.487,0,0,1-2.108,0L8.9,17.045A1.494,1.494,0,0,1,8.9,14.93Z"/>
                </svg>
                <svg slot="hooper-next" class="pp-addons ar r-right">
                  <path d="M15.987.563A15.425,15.425,0,1,1,.563,15.987,15.422,15.422,0,0,1,15.987.563ZM23.072,14.93,14.644,6.5a1.487,1.487,0,0,0-2.108,0L11.478,7.56a1.487,1.487,0,0,0,0,2.108L17.8,15.987l-6.319,6.319a1.487,1.487,0,0,0,0,2.108l1.057,1.057a1.487,1.487,0,0,0,2.108,0l8.428-8.428A1.494,1.494,0,0,0,23.072,14.93Z"/>
                </svg>
              </HooperNavigation>
              <HooperPagination slot="hooper-addons"></HooperPagination>
            </Hooper>

            <v-container style="height: 150px">
              <Hooper
                  v-bind:ref="refHooperDetail(course)"
                  class="ma-0"
                  :settings="hooperDetailSettings"
              >
                <Slide v-for="video in course.resources" :key="video.uuid" :index="video.uuid">
                  <VideoDetail v-bind:video="video" />
                </Slide>
              </Hooper>
            </v-container>
          </v-row>
          <v-divider dark class="mt-10"></v-divider>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import VideoSplash from "./VideoSplash";
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';
import VideoDetail from "./VideoDetail";
import YouTubeWithCourses from "./YouTubeWithCourses";
import router from "../router";
import shared from "../shared";

export default {
  name: "CourseList",
  props: ["data"],
  components: {
    YouTubeWithCourses,
    VideoDetail,
    VideoSplash, Hooper, Slide, HooperPagination, HooperNavigation},
  computed: {
    ...mapState(["courses", "playing", "video"]),
    ...mapGetters(["courses", "playing", "video"]),
  },
  methods: {
    ...mapActions(["doPlayVideo"]),
    anchor(course){
      return shared.courseToAnchor(course);
    },
    jump(course){
      router.push({ path: `/#${shared.courseToAnchor(course)}`});
    },
    refHooperCourses(course){
      return `hooper-course-${shared.courseToAnchor(course)}`;
    },
    refHooperDetail(course){
      return `hooper-detail-${shared.courseToAnchor(course)}`;
    },
    updateCarousel(payload, course) {

      const detail = `hooper-detail-${shared.courseToAnchor(course)}`;

      /*
       Bugs with infinite scrolling and events
       @see https://github.com/baianat/hooper/issues/158
       @see https://github.com/baianat/hooper/issues/195
      */

      let currentSlide = payload.currentSlide;

      if (payload.currentSlide === -1) {
        currentSlide = course.resources.length - 1;
      } else if (payload.currentSlide === course.resources.length) {
        currentSlide = 0;
      }

      this.$refs[detail][0].slideTo(currentSlide);

    },
    updateCarouselFromHover(course, place){
      const detail = `hooper-detail-${shared.courseToAnchor(course)}`;
      this.$refs[detail][0].slideTo(place)
    },
  },
  data () {
    return {
      tab: null,
      images:{},
      hopperBannerSettings: {
        infiniteScroll: true,
        centerMode: true,
        itemsToShow: 1,
        autoPlay: true,
        playSpeed: 40000,
        vertical: false,
        mouseDrag: true,
        touchDrag: true,
        wheelControl: false,
        keysControl: false,
        shortDrag: false,
      },
      hooperVideosSettings: {
        itemsToShow: 5,
        infiniteScroll: true,
        mouseDrag: true,
        touchDrag: true,
        wheelControl: false,
        keysControl: false,
        shortDrag: false,
      },
      hooperDetailSettings: {
        itemsToShow: 1,
        mouseDrag: false,
        touchDrag: false,
        wheelControl: false,
        keysControl: false,
        shortDrag: false,
      },
    }
  },
}
</script>

<style scoped>

#banner {
  height: fit-content;
}
.hooper-bg {
  background-image: url("/images/slider.jpg");
  border-radius: 14px;
}

.pp-addons {
  fill: rgba(255, 255, 255, 0.49);
  width: 40px;
}

.pp-img {
  object-fit: contain;
}

.box {
  object-fit: contain !important;
}

.ar {
  position: absolute;
  z-index: 1;
}

.r-left {
  left: 6px;
}

.r-right {
  left: 15px;
}



@media only screen and (min-width: 1700px) {
  .pp-large {
    height: 500px;
    margin-right: 3rem;
  }

  .pp-large-2 {
    font-size: 1.5rem !important;
  }

  .pl-xl-20 {
    margin-left: 13rem;
  }

  .pr-xl-20 {
    margin-right: 13rem;
  }

  .pp-title {
    font-size: 4.3rem !important;
    line-height: 5.5rem;
  }
}

@media only screen and (max-width: 1024px) {
  .pp-tablet {
    width: 300px;
  }

  .pp-medium {
    height: 300px;
    background-size: cover;
    position: relative;
    top: 2.5rem;
    transform: scale(1.5, 1.5);
    z-index: 0;
    object-fit: cover;
  }

}
</style>